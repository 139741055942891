@import '../../styles/variables'

#home
    background-color: $gunmetal
    background: linear-gradient(0deg, darken(#FFF, 20%), #FFF)
    color: lighten(#000, 25%)

    p, h1, h2, h3, h4, h5, h6
        margin: 0

    main div
        box-sizing: border-box

    .hero-container
        height: 100vh
        width: 100%
        padding: 5rem 2rem 2rem 4rem

        @media (max-width: $mobile-width)
            padding: 2rem 0.5rem 1rem
            display: flex
            flex-direction: column

        .hero-content
            max-width: 800px
            margin: 0 auto

        .bio
            text-align: center

        .name
            font-size: 3rem
            margin: 0

            @media (max-width: $mobile-width)
                font-size: 1.5rem

        .links

            display: flex
            justify-content: center
            margin-top: 1rem

            a
                margin-left: 1rem
                color: lighten(#000, 25%)
                font-size: 1.5rem
                text-decoration: none
                transition: all 0.2s ease-in-out

                display: flex
                align-items: center

            a:hover
                color: $burnt-sienna

            @media (max-width: $mobile-width)
                a
                    font-size: 1rem

                svg
                    height: 1rem
                    width: 1rem

    .projects-showcase

        margin: 0 auto
        margin-top: 3rem

        height: 500px
        width: 90%

        display: flex

        & *
            box-sizing: border-box

        @media (max-width: $mobile-width)
            margin: 0 auto
            flex: 1

        .content
            flex: 1
            display: flex
            align-items: center
            gap: 1rem
            overflow-y: scroll

        .project-content
            height: 100%

        .project
            height: 95%
            aspect-ratio: 9/16
            position: relative
            color: darken(#FFF, 0%)

            transition: all 0.2s ease-out
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px

            @media (max-width: $mobile-width)
                height: 75%

            &::before
                content: ''
                display: block
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                background: $gunmetal
                opacity: 0

            &:hover
                height: 100%
                background-position-x: center !important
                background-position-y: center !important

                @media (max-width: $mobile-width)
                    height: 80%

        .project-title
            font-size: 1.75rem
            padding: 1rem
            height: 30%
            font-weight: bold
            text-shadow: #000 1px 1px

        .project-body
            position: relative
            height: 70%
            padding: 1rem
            background-color: $gunmetal
            opacity: 0.9

        .project-description
            height: 50%

        .project-technologies
            height: 30%
            display: flex
            flex-wrap: wrap
            gap: 0.5rem

            span
                height: 1.5rem
                background-color: #FFF
                color: #000
                padding: 1px 3px
                border-radius: 4px

        .project-actions
            display: flex
            align-items: flex-end
            height: 20%

            a
                flex: 1
                display: block
                text-align: center
                color: #FFF

                &:hover
                    color: $burnt-sienna

                &:active
                    color: lighten($burnt-sienna, 10%)

@import '../../styles/variables'

#project
    background-color: $gunmetal
    background: linear-gradient(0deg, darken(#FFF, 20%), #FFF)
    color: lighten(#000, 25%)

    p, h1, h2, h3, h4, h5, h6
        margin: 0

    main, div
        box-sizing: border-box

    main
        min-height: 100vh

    .title
        font-size: 2rem
        font-weight: 700
        margin-bottom: 2rem

    .description
        margin-bottom: 2rem

    a
        color: $burnt-sienna
        text-decoration: none
        transition: all 0.2s ease-in-out

    a:hover
        color: $dark-sky-blue

    .container
        max-width: 650px
        margin: 0 auto
        padding: 2rem

    .breadcrumb
        display: flex
        align-items: center
        padding: 0
        margin-bottom: 2rem

        list-style-type: none

    .markdown
        font-size: 1.2rem
        line-height: 1.5
        margin: 2rem 0

        h2
            font-size: 1.25rem
            margin-bottom: 1rem
        p
            margin-bottom: 2rem

        code
            background-color: $gunmetal
            color: $light-cyan
            padding: 0.2rem 0.4rem
            border-radius: 5px

        img
            max-width: 100%
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap')

$mobile-width: 600px

$barcelona: url(https://images.unsplash.com/photo-1507619579562-f2e10da1ec86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80)
$header-height: 4rem
$main-height: 100vh

$jet-black: #2f2f2f
$white: #fdfdfd
$isabelline: #f4ebe8
$baby-powder: #fdfffc

$bdazzled-blue: #3d5a80ff
$dark-sky-blue: #98c1d9ff
$light-cyan: #e0fbfcff
$burnt-sienna: #ee6c4dff
$gunmetal: #293241ff

// Fonts

%monserrat
    font-family: 'Montserrat', sans-serif

%playfair-display
    font-family: 'Playfair Display', serif
